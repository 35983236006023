import { Outlet, redirect, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../database/firebase";
import { useEffect } from "react";
import A_Button from "../components/admin/A_Button";

const AdminDefaultRootLayout = () => {
  const navigate = useNavigate();

  const logMeOut = async () => {
    try {
      await signOut(auth);

      navigate("./login");
    } catch (err) {
      console.error(err);
    }
  };

  const home = async () => {
    navigate("./");
  };

  return (
    <div className="mt-3 container">
      <>
        <div className="d-flex justify-content-between">
          <A_Button
            icon={"logout"}
            functionOnClick={logMeOut}
            theme="warning"
            full={false}
          />
          <A_Button icon={"home"} functionOnClick={home} full={false} />
        </div>

        <hr />
      </>

      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default AdminDefaultRootLayout;
