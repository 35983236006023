import React from "react";
import { useNavigate } from "react-router-dom";

export default function PostComponent({ postDetails }) {
  const getMonthNumber = (month) => {
    switch (month) {
      case "Jan":
        return 1;
      case "Feb":
        return 2;
      case "Mar":
        return 3;
      case "Apr":
        return 4;
      case "May":
        return 5;
      case "Jun":
        return 6;
      case "Jul":
        return 7;
      case "Aug":
        return 8;
      case "Sep":
        return 9;
      case "Oct":
        return 10;
      case "Nov":
        return 11;
      case "Dec":
        return 12;

      default:
        return 0;
    }
  };

  const navigate = useNavigate();
  const goToPostPage = () => {
    navigate(postDetails.id);
  };
  const rawTimeArray = postDetails.created.toDate().toString().split(" ");
  const time =
    rawTimeArray[2] +
    "/" +
    getMonthNumber(rawTimeArray[1]) +
    "/" +
    rawTimeArray[3];

  return (
    <div
      style={{ backgroundColor: "#8688B0", cursor: "pointer" }}
      className="rounded-2 p-3 m-3 text-white d-flex justify-content-between align-items-center"
      onClick={goToPostPage}
    >
      <div className="list">
        <div className="list-item mb-5">{postDetails.title}</div>
        <div className="list-item">{time}</div>
      </div>

      <div className="list">
        <div className="list-item mb-5">Celý příspěvek</div>
        <div className="list-item text-end">
          <span className="material-symbols-outlined">arrow_circle_right</span>
        </div>
      </div>
    </div>
  );
}
