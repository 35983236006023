import A_Button from "./A_Button";

const A_PreCreateOverlay = ({ postObject, setPostObject }) => {
  return (
    <div className="">
      {postObject.team == null ? (
        <div className="border">
          <h1 className="text-center pt-5">který oddíl? </h1>

          <div className="p-5 d-flex flex-nowrap">
            <A_Button
              text={"1. Oddíl"}
              functionOnClick={(e) => {
                setPostObject({ ...postObject, team: 1 });
              }}
              extraStyle={"me-5"}
            ></A_Button>
            <A_Button
              text={"2. Oddíl"}
              functionOnClick={(e) => {
                setPostObject({ ...postObject, team: 2 });
              }}
            ></A_Button>
          </div>
        </div>
      ) : (
        <div className="border">
          <h1 className="text-center pt-5">Aktuality nebo dokumenty?</h1>
          <div className="p-5 d-flex flex-nowrap">
            <A_Button
              text={"Aktuality"}
              functionOnClick={(e) => {
                setPostObject({ ...postObject, type: "aktuality" });
              }}
              extraStyle={"me-5"}
            ></A_Button>
            <A_Button
              text={"Dokumenty"}
              functionOnClick={(e) => {
                setPostObject({ ...postObject, type: "dokumenty" });
              }}
            ></A_Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default A_PreCreateOverlay;
