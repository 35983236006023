import { db } from "./firebase";
import {
  getDoc,
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";

const postsCollectionRef = collection(db, "posts");

//Get posts for database
export const postsLoader = async () => {
  try {
    const data = await getDocs(postsCollectionRef);
    if (data != null) {
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      return filteredData;
    }

    return null;
  } catch (err) {
    console.error(err);
  }
};

//Load a single post with url param
export const postLoader = async ({ params }) => {
  try {
    const postRefDoc = doc(db, "posts", params.id);

    const postDoc = await getDoc(postRefDoc);

    const filteredData = { ...postDoc.data(), id: postDoc.id };

    return filteredData;
  } catch (err) {
    console.error(err);
  }
};

export const contactLoader = async ({ params }) => {
  try {
    const contactRefDoc = doc(db, "contacts", params.id);

    const contactDoc = await getDoc(contactRefDoc);

    const filteredData = { ...contactDoc.data(), id: contactDoc.id };

    return filteredData;
  } catch (err) {
    console.error(err);
  }
};

export const photoLoader = async ({ params }) => {
  try {
    const photoRefDoc = doc(db, "photos", params.id);

    const photoDoc = await getDoc(photoRefDoc);

    const filteredData = { ...photoDoc.data(), id: photoDoc.id };

    return filteredData;
  } catch (err) {
    console.error(err);
  }
};

export const postLoaderWithID = async (postId) => {
  try {
    const postRefDoc = doc(db, "posts", postId);

    const postDoc = await getDoc(postRefDoc);

    const filteredData = { ...postDoc.data(), id: postDoc.id };

    return filteredData;
  } catch (err) {
    console.error(err);
  }
};

//Create new post
export const addPost = async (postObject) => {
  try {
    const dateOfCreation = serverTimestamp();

    await addDoc(postsCollectionRef, {
      created: dateOfCreation,
      title: postObject.title,
      type: postObject.type,
      url: postObject.url,
      text: postObject.text,
    });
  } catch (err) {
    console.log(err);
  }
};

export const editPost = async (postId, newPost) => {
  try {
    const postRefDoc = doc(db, "posts", postId);

    await updateDoc(postRefDoc, {
      title: newPost.title,
      type: newPost.type,
      url: newPost.url,
      text: newPost.text,
    });
  } catch (err) {
    console.log(err);
  }
};

export const editContact = async (postId, newPost) => {
  try {
    const postRefDoc = doc(db, "posts", postId);

    await updateDoc(postRefDoc, {
      title: newPost.title,
      type: newPost.type,
      url: newPost.url,
      text: newPost.text,
    });
  } catch (err) {
    console.log(err);
  }
};

export const editPostAddURLS = async (postId, newUrls, updateImagesInPost) => {
  try {
    const postRefDoc = doc(db, "posts", postId);

    await updateDoc(postRefDoc, {
      urls: arrayUnion(newUrls),
    });

    updateImagesInPost(await postLoaderWithID(postId));
  } catch (err) {
    console.log(err);
  }
};

export const deletePost = async (postId) => {
  const postRefDoc = doc(db, "posts", postId);
  try {
    await deleteDoc(postRefDoc);
  } catch (err) {
    console.log(err);
  }
};
