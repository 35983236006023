import { Editor } from "@tinymce/tinymce-react";

const A_TextEditor = ({ postObject, setPostObject }) => {
  return (
    <>
      <Editor
        apiKey="oqoakyc7aejkmitbhhfjtkskyymc97rfvqv4jhgfbyjfq8hv"
        initialValue={postObject.text}
        onChange={(e) => {
          setPostObject({ ...postObject, text: e.target.getContent() });
        }}
        init={{
          skin: "oxide",
          height: 900,
          menubar: true,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "link |" +
            "undo redo | blocks | " +
            "bold italic | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px;  }",
        }}
      />
    </>
  );
};

export default A_TextEditor;
