import {
  NavLink,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import A_PostComponent from "../../components/admin/A_PostComponent";
import A_Button from "../../components/admin/A_Button";
import { useState } from "react";
import { useEffect } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { auth, db } from "../../database/firebase";
import A_ListOfPosts from "../../components/admin/A_ListOfPosts";
import A_ListOfContacts from "../../components/admin/A_ListOfContacts";
import A_ListOfPhotos from "../../components/admin/A_ListOfPhotos";
import A_ListOfPeriods from "../../components/admin/A_ListOfPeriods";
import A_Agenda from "../../components/admin/A_Agenda";

export const postsLoader = async () => {
  const user = auth.currentUser;
  const order = "created";

  if (user?.uid != "TxwcMeIsZdfXRXf3PSGC1Cu8lRh2" || !user) {
    return redirect("./login");
  }

  try {
    const postsCollectionRef = collection(db, "posts");

    const contactCollectionRef = collection(db, "contacts");

    const periodsCollectionRef = collection(db, "periods");

    const qPeriods = query(periodsCollectionRef, orderBy("timePeriod", "desc"));

    const qforPost = query(
      postsCollectionRef,
      where("team", "==", 2),
      orderBy("created")
    );

    const data = await getDocs(qforPost);

    const dataOfPeriods = await getDocs(qPeriods);

    const q = query(
      contactCollectionRef,
      where("team", "==", 1),
      orderBy("order")
    );

    const data2 = await getDocs(q);

    const q2 = query(
      contactCollectionRef,
      where("team", "==", 2),
      orderBy("order")
    );

    const data3 = await getDocs(q2);

    const filteredData = {
      posts: [],
      contacts1: [],
      contacts2: [],
      periods: [],
    };

    if (data != null) {
      filteredData.posts = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    }

    if (data2 != null) {
      filteredData.contacts1 = data2.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    }

    if (data3 != null) {
      filteredData.contacts2 = data3.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    }

    if (dataOfPeriods != null) {
      filteredData.periods = dataOfPeriods.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    }

    return filteredData;
  } catch (err) {
    console.error(err);
  }
};

const A_Home = () => {
  const filteredData = useLoaderData();
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(0);
  const switchTitles = () => {
    switch (tabActive) {
      case 0:
        return "Spravovat příspěvky";
      case 1:
        return "Spravovat fotogalerii";
      case 2:
        return "Spravovat kontakty";
    }
  };

  const switchPanels = () => {
    switch (tabActive) {
      case 0:
        return (
          <>
            <A_Agenda />
            <A_ListOfPosts Array={filteredData.posts} />
          </>
        );
      case 1:
        return <A_ListOfPeriods Array={filteredData.periods} />;

      case 2:
        return (
          <A_ListOfContacts
            Array1={filteredData.contacts1}
            Array2={filteredData.contacts2}
          />
        );
    }
  };
  const toCreate = () => {
    switch (tabActive) {
      case 0:
        return navigate("create");
      case 1:
        return navigate("createperiod");
      case 2:
        return navigate("createcontact");
    }
  };

  return (
    <>
      <div className="d-flex mb-3">
        <div className="w-75 d-flex">
          <A_Button
            icon={"newspaper"}
            functionOnClick={(e) => setTabActive(0)}
            theme={tabActive == 0 ? "dark" : "light"}
            full={false}
          />
          <A_Button
            icon={"photo_library"}
            functionOnClick={(e) => setTabActive(1)}
            theme={tabActive == 1 ? "dark" : "light"}
            full={false}
          />
          <A_Button
            icon={"manage_accounts"}
            functionOnClick={(e) => setTabActive(2)}
            extraStyle={"me-3"}
            theme={tabActive == 2 ? "dark" : "light"}
            full={false}
          />
          <span className="my-auto">{switchTitles()}</span>
        </div>

        <div className="d-flex justify-content-end w-100">
          <A_Button icon={"Add"} functionOnClick={toCreate} full={false} />
        </div>
      </div>
      <hr />

      {switchPanels()}
    </>
  );
};

export default A_Home;
