import React from "react";
import PostComponent from "./PostComponent";

export default function PostDisplay({ posts }) {
  return (
    <div>
      {posts.map((post, index) => (
        <PostComponent postDetails={post} />
      ))}
    </div>
  );
}
