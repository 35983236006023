import React from "react";
import { Outlet } from "react-router-dom";

export default function ProVerejnostRootLayout() {
  return (
    <div>
      <div style={{ height: "80px" }}></div>
      <div className="container my-5">
        <Outlet />
      </div>
    </div>
  );
}
