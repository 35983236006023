import React from "react";
import ContactComponent from "./ContactComponent";
import ContactComponentImportant from "./ContactComponentImportant";
import { Grid, ImageList } from "@mui/material";

export default function ContactDisplay({ contacts, Icontacts }) {
  return (
    <div className="mx-xl-5 mx-2">
      {Icontacts.map((contact, index) => (
        <ContactComponentImportant contactDetails={contact} index={index} />
      ))}
      <div className="d-flex flex-wrap ">
        {contacts.map((contact, index) => (
          <ContactComponent contactDetails={contact} />
        ))}
      </div>
    </div>
  );
}
