const A_Button = ({
  functionOnClick,
  text,
  icon,
  disabled = false,
  theme = "primary",
  extraStyle,
  specialStyle = {},
  full = true,
}) => {
  const width = full ? "w-100" : "";
  const between = icon != null ? "justify-content-between" : "";

  const classText = `btn-${theme} btn ${width} ${between} rounded-0 input-group-text d-flex align-items-center  ${extraStyle}`;
  return (
    <button
      className={classText}
      style={specialStyle}
      onClick={(e) => functionOnClick()}
      disabled={disabled ? "disabled" : ""}
    >
      {icon != null ? (
        <span className="material-symbols-outlined ">{icon}</span>
      ) : (
        <></>
      )}

      {text}
    </button>
  );
};

export default A_Button;
