import React from "react";

import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { auth, db } from "../database/firebase";

import { redirect, useLoaderData } from "react-router-dom";
import PeriodDisplay from "../components/PeriodDisplay.js";

export const FotoLoader = async () => {
  const user = auth.currentUser;

  if (
    (user?.uid != "heoKu8ND3EZCcuNBQKqcIVjwd0v2" &&
      user?.uid != "jqYxa3JBgzRxcOPneiHxHtaOWH13" &&
      user?.uid != "TxwcMeIsZdfXRXf3PSGC1Cu8lRh2") ||
    !user
  ) {
    return redirect("./login");
  }

  try {
    const PeriodCollectionRef = collection(db, "periods");
    const qPeriods = query(PeriodCollectionRef, orderBy("timePeriod", "desc"));

    const dataOfPeriods = await getDocs(qPeriods);

    if (dataOfPeriods != null) {
      const filteredData = dataOfPeriods.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      return filteredData;
    }

    return null;
  } catch (err) {
    console.error(err);
  }
};

export default function Fotogalarie() {
  const periods = useLoaderData();
  return (
    <div>
      <PeriodDisplay periods={periods} />
    </div>
  );
}
