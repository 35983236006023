import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCrd9HeZ3Bj898pqSk-gb4PeXDXi67Lci8",
  authDomain: "manere-db48c.firebaseapp.com",
  projectId: "manere-db48c",
  storageBucket: "manere-db48c.appspot.com",
  messagingSenderId: "221417936041",
  appId: "1:221417936041:web:11be88849d7a145f5c45b8",
  measurementId: "G-6PY63Q6QCC",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
