import React from "react";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../database/firebase";

import { redirect, useLoaderData } from "react-router-dom";
import PostDisplay from "../../components/PostDisplay.js";

export const OddilTwoAktualityLoader = async () => {
  const user = auth.currentUser;

  if (
    (user?.uid != "jqYxa3JBgzRxcOPneiHxHtaOWH13" &&
      user?.uid != "TxwcMeIsZdfXRXf3PSGC1Cu8lRh2") ||
    !user
  ) {
    return redirect("/oddil_two/login");
  }

  try {
    const postsCollectionRef = collection(db, "posts");

    const agendaCollectionRef = doc(db, "agenda", "rBnTZhFLV2OhEle4L9uW");

    const q = query(
      postsCollectionRef,
      where("team", "==", 2),
      where("type", "==", "aktuality"),
      orderBy("created", "desc")
    );

    const data = await getDocs(q);
    const agendaData = await getDoc(agendaCollectionRef);

    const combiData = { filteredData: [], agendaData: null };

    if (data != null && agendaData != null) {
      combiData.filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      combiData.agendaData = { ...agendaData.data(), id: agendaData.id };

      return combiData;
    }

    return null;
  } catch (err) {
    console.error(err);
  }
};

export default function Aktuality() {
  const combiData = useLoaderData();
  return (
    <div>
      <h1 className="mb-5">Aktuality</h1>
      <div dangerouslySetInnerHTML={{ __html: combiData.agendaData.teamOne }} />
      <hr />
      <PostDisplay posts={combiData.filteredData} />
    </div>
  );
}
