import { useLoaderData } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { db, storage } from "../../database/firebase";
import A_TextEditor from "../../components/admin/A_TextEditor";
import A_Button from "../../components/admin/A_Button";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import A_PreCreateOverlayContact from "../../components/admin/A_PreCreateOverlayContact";
import { useEffect } from "react";
import A_DokumentyComponent from "../../components/admin/A_DokumentyComponent";

export default function A_Edit() {
  const [oldPostObject, setOldPostObject] = useState(useLoaderData());
  const [files, setFiles] = useState([]);
  const [postObject, setPostObject] = useState(oldPostObject);

  const navigate = useNavigate();

  const UpdatePost = async () => {
    await editPost(postObject);
    navigate("/admin");
  };

  const DeleterWholePost = async () => {
    await deletePost(postObject);
    navigate("/admin");
  };

  const uploadFiles = () => {
    Array.from(files).forEach(async (file) => {
      const filesFolderRef = ref(storage, `postFiles/${file.name}`);

      await uploadBytes(filesFolderRef, file);

      await getDownloadURL(filesFolderRef).then((URL) => {
        let newArrayOfURLs = postObject.pdfs;
        newArrayOfURLs.push(URL);

        setPostObject({ ...postObject, pdfs: newArrayOfURLs });
      });
    });

    alert("Úspěšně nahráno");
  };

  const removeFromList = (Doc) => {
    let newArray = postObject.pdfs;
    const index = newArray.indexOf(Doc);
    newArray.splice(index, 1);

    setPostObject({ ...postObject, pdfs: newArray });
  };

  const deletePost = async () => {
    await postObject.pdfs.forEach((pdf) => {
      const httpsReference = ref(storage, pdf.toString());

      deleteObject(httpsReference);
    });

    const postRefDoc = doc(db, "posts", postObject.id);
    try {
      await deleteDoc(postRefDoc);
    } catch (err) {
      console.log(err);
    }
  };

  const editPost = async () => {
    try {
      const postDocRef = doc(db, "posts", postObject.id);

      await updateDoc(postDocRef, {
        team: postObject.team,
        text: postObject.text,
        title: postObject.title,
        type: postObject.type,
        pdfs: postObject.pdfs,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <>
        <div className="d-flex align-items-center justify-content-between">
          <label className="me-3">Oddil</label>
          <select
            className="form-control rounded-0 w-75"
            defaultValue={oldPostObject.team}
            onChange={(e) =>
              setPostObject({
                ...postObject,
                team: parseInt(e.target.value),
              })
            }
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
          </select>
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-between mt-3">
          <label className="me-3">Typ</label>
          <select
            className="form-control rounded-0 w-75"
            defaultValue={oldPostObject.type}
            onChange={(e) =>
              setPostObject({ ...postObject, type: e.target.value })
            }
          >
            <option value="aktuality">Aktuality</option>
            <option value="dokumenty">Dokumenty</option>
          </select>
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <label className="me-3">Title</label>
          <input
            className="form-control rounded-0 w-75"
            placeholder="Title..."
            defaultValue={oldPostObject.title}
            onChange={(e) =>
              setPostObject({ ...postObject, title: e.target.value })
            }
          />
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <label className="me-3">Dokumenty (volitelný)</label>
          <div className="w-75 d-flex">
            <input
              className=" form-control rounded-0"
              type="file"
              multiple="multiple"
              accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, image/png, image/jpeg"
              onChange={(e) => setFiles(e.target.files)}
            />
            <button
              className="btn-primary btn  input-group-text rounded-0"
              onClick={uploadFiles}
            >
              Upload
            </button>
          </div>
        </div>
        {postObject.pdfs?.map((pdf) => (
          <A_DokumentyComponent Doc={pdf} removeFromList={removeFromList} />
        ))}

        <hr />
        <label className="me-3 mb-3">About</label>
        <A_TextEditor
          postObject={postObject}
          setPostObject={setPostObject}
        ></A_TextEditor>

        {/* Create new post */}
        <hr />

        <A_Button
          functionOnClick={UpdatePost}
          text={"Úprava zprávy"}
          icon={"edit"}
        />
        <hr />
        <A_Button
          functionOnClick={DeleterWholePost}
          text={"Smazat příspěvek"}
          icon={"delete"}
          theme="danger"
        />
      </>

      <div className="mb-3"></div>
    </>
  );
}
