import { useNavigate } from "react-router-dom";
import A_Button from "./A_Button";

function giveTime(postDetails) {
  return (
    postDetails.created.toDate().toString().split(" ")[2] +
    "/" +
    postDetails.created.toDate().toString().split(" ")[1] +
    "/" +
    postDetails.created.toDate().toString().split(" ")[3]
  );
}

export default function A_ListOfPosts({ Array }) {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="row mx-0 row-auto">
          <div className="col-1 bg-light p-1 ps-2">#</div>
          <div className="col-2 bg-light ms-2 p-1 ps-2">Created</div>
          <div className="col-2 bg-light ms-2 p-1 ps-2">Typ</div>
          <div className="col bg-light ms-2 p-1 ps-2">Titul</div>
        </div>

        {Array != null ? (
          <>
            {Array.map((post, index) => (
              <div key={index} className=" row row-auto mx-0 my-2">
                <div className="col-1 border p-1 ps-2">{index}</div>
                <div className="col-2 border ms-2 p-1 ps-2">
                  {giveTime(post)}
                </div>
                <div className="col-2 border ms-2 p-1 ps-2">{post.type}</div>
                <div className="col border ms-2 p-1 ps-2">{post.title}</div>
                <div className="col col-auto ms-2 p-0  ">
                  <A_Button
                    icon={"edit"}
                    functionOnClick={(e) => navigate("edit/" + post.id)}
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <h1>EMPTY</h1>
          </>
        )}
      </div>
    </>
  );
}
