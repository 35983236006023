import React from "react";

function isOdd(num) {
  return num % 2;
}

export default function ContactComponentImportant({ contactDetails, index }) {
  return (
    <>
      <div className="d-md-flex d-none">
        {isOdd(index) == 0 ? (
          <div className="row p-2">
            <div className="col col-auto">
              <img
                src={contactDetails.image}
                alt={contactDetails.name}
                style={{ height: "200px" }}
              />
            </div>
            <div className="col ms-3 my-auto">
              <h2 style={{ marginBottom: "-2px" }}>{contactDetails.name}</h2>
              <span style={{ fontSize: "0.8rem" }}>{contactDetails.role}</span>
              <div
                className="mt-3"
                style={{ fontSize: "0.8rem" }}
                dangerouslySetInnerHTML={{ __html: contactDetails.text }}
              />
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#299b60" }}
                >
                  <span className="me-3 material-symbols-outlined ">call</span>
                  {contactDetails.phone}
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#8688B0" }}
                >
                  <span className="me-3 material-symbols-outlined ">mail</span>
                  <a
                    className="text-decoration-none"
                    style={{ color: "#8688B0" }}
                    href={`mailto:${contactDetails.email}`}
                  >
                    {contactDetails.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-5 p-2">
            <div className="col ms-3 my-auto">
              <h2 style={{ marginBottom: "-2px" }}>{contactDetails.name}</h2>
              <span style={{ fontSize: "0.8rem" }}>{contactDetails.role}</span>
              <div
                className="mt-3"
                style={{ fontSize: "0.8rem" }}
                dangerouslySetInnerHTML={{ __html: contactDetails.text }}
              />
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#299b60" }}
                >
                  <span className="me-3 material-symbols-outlined ">call</span>
                  {contactDetails.phone}
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#8688B0" }}
                >
                  <span className="me-3 material-symbols-outlined ">mail</span>
                  <a
                    className="text-decoration-none"
                    style={{ color: "#8688B0" }}
                    href={`mailto:${contactDetails.email}`}
                  >
                    {contactDetails.email}
                  </a>
                </div>
              </div>
            </div>
            <div className="col col-auto">
              <img
                src={contactDetails.image}
                alt={contactDetails.name}
                style={{ height: "200px" }}
              />
            </div>
          </div>
        )}
      </div>

      <div
        className="d-md-none flex-item text-center mx-auto p-3 mt-5"
        style={{
          minWidth: "300px",
          maxWidth: "320px",
        }}
      >
        <img
          src={contactDetails.image}
          style={{
            minWidth: "180px",
            maxWidth: "200px",
          }}
        />
        <h3 className="mt-3">{contactDetails.name}</h3>
        <span>{contactDetails.role}</span>
        <div
          className="mt-3 text-start"
          style={{ fontSize: "0.8rem" }}
          dangerouslySetInnerHTML={{ __html: contactDetails.text }}
        />
        <div style={{ fontSize: "0.9rem" }}>
          <div
            className="d-flex align-items-center"
            style={{ color: "#299b60" }}
          >
            <span className="me-4 material-symbols-outlined ">call</span>
            {contactDetails.phone}
          </div>
          <div
            className="d-flex align-items-center mt-2"
            style={{ color: "#8688B0" }}
          >
            <span className="me-4 material-symbols-outlined ">mail</span>
            <a
              className="text-decoration-none"
              style={{ color: "#8688B0" }}
              href={`mailto:${contactDetails.email}`}
            >
              {contactDetails.email}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
