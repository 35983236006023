import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { auth } from "../database/firebase";

export default function Oddil_OneRootLayout() {
  return (
    <main>
      <div style={{ height: "80px" }}></div>
      <div className="container my-5">
        <Outlet />
      </div>
    </main>
  );
}
