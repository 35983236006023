import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { db, storage } from "../../database/firebase";
import A_TextEditor from "../../components/admin/A_TextEditor";
import A_Button from "../../components/admin/A_Button";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import A_PreCreateOverlayContact from "../../components/admin/A_PreCreateOverlayContact";

const addContact = async (contactObject) => {
  try {
    const dateOfCreation = serverTimestamp();
    let length = 0;

    const contactCollectionRef = collection(db, "contacts");
    const q = query(
      contactCollectionRef,
      where("team", "==", contactObject.team)
    );

    let data = await getDocs(q);
    if (data != null) {
      data = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      length = data.length;
    }

    console.log(length);

    await addDoc(collection(db, "contacts"), {
      created: dateOfCreation,
      order: length,
      important: contactObject.important,
      name: contactObject.name,
      role: contactObject.role,
      text: contactObject.text,
      email: contactObject.email,
      phone: contactObject.phone,
      team: contactObject.team,
      image: contactObject.image,
    });
  } catch (err) {
    console.error(err);
  }
};

const A_CreateContact = () => {
  const [file, setFile] = useState(null);
  const [contactObject, setContactObject] = useState({
    order: null,
    important: false,
    name: "",
    role: "",
    text: "",
    email: "",
    phone: "",
    team: null,
    image: "",
  });

  const navigate = useNavigate();

  const createNewContact = async () => {
    await addContact(contactObject);
    console.log(contactObject);
    navigate("/admin");
  };

  const uploadFile = async () => {
    const filesFolderRef = ref(storage, `contactImages/${file.name}`);

    await uploadBytes(filesFolderRef, file);

    await getDownloadURL(filesFolderRef).then((URL) => {
      setContactObject({ ...contactObject, image: URL });
    });

    alert("Úspěšně nahráno");
  };

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <>
      {contactObject.team != null ? (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <label className="me-3">Oddil</label>
            <select
              className="form-control rounded-0 w-75"
              defaultValue={contactObject.team}
              onChange={(e) =>
                setContactObject({
                  ...contactObject,
                  team: parseInt(e.target.value),
                })
              }
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
          </div>
          <div className="d-flex mt-2 align-items-center justify-content-end">
            <label className="me-3">Important</label>
            <input
              className="form-control form-check-input rounded-0"
              type="checkbox"
              onChange={(e) =>
                setContactObject({
                  ...contactObject,
                  important: e.target.checked,
                })
              }
            />
          </div>

          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <label className="me-3">Name</label>
            <input
              className="form-control rounded-0 w-75"
              placeholder="Name..."
              onChange={(e) =>
                setContactObject({ ...contactObject, name: e.target.value })
              }
            />
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <label className="me-3">Role</label>
            <input
              className="form-control rounded-0 w-75"
              placeholder="Role..."
              onChange={(e) =>
                setContactObject({ ...contactObject, role: e.target.value })
              }
            />
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <label className="me-3">Email</label>
            <input
              className="form-control rounded-0 w-75"
              placeholder="Email..."
              onChange={(e) =>
                setContactObject({ ...contactObject, email: e.target.value })
              }
            />
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <label className="me-3">Phone number</label>
            <input
              className="form-control rounded-0 w-75"
              placeholder="123 456 789"
              onChange={(e) =>
                setContactObject({ ...contactObject, phone: e.target.value })
              }
            />
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <label className="me-3">Profile picture</label>
            <div className="w-75 d-flex">
              <input
                className=" form-control rounded-0"
                type="file"
                multiple="multiple"
                accept="image/png, image/jpeg"
                onChange={onChangeFile}
              />
              <button
                className="btn-primary btn  input-group-text rounded-0"
                onClick={uploadFile}
              >
                Upload
              </button>
            </div>
          </div>
          <hr />

          <label className="me-3 mb-3">About</label>
          <A_TextEditor
            postObject={contactObject}
            setPostObject={setContactObject}
          ></A_TextEditor>

          {/* Create new post */}
          <hr />

          {contactObject.text == "" ? (
            <label className="text-bg-danger w-100 mb-2 ps-2">
              Je třeba přidat text
            </label>
          ) : (
            <></>
          )}
          {contactObject.text != "" ? (
            <A_Button
              functionOnClick={createNewContact}
              text={"Zveřejnit příspěvek"}
              icon={"new_window"}
            />
          ) : (
            <A_Button
              text={"Zveřejnit příspěvek"}
              icon={"new_window"}
              disabled={true}
            />
          )}
        </>
      ) : (
        <>
          <A_PreCreateOverlayContact
            postObject={contactObject}
            setPostObject={setContactObject}
          />
        </>
      )}

      <div className="mb-3"></div>
    </>
  );
};

export default A_CreateContact;
