import { useLoaderData } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { db, storage } from "../../database/firebase";
import A_TextEditor from "../../components/admin/A_TextEditor";
import A_Button from "../../components/admin/A_Button";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import A_PreCreateOverlayContact from "../../components/admin/A_PreCreateOverlayContact";
import { useEffect } from "react";

export default function A_EditContact() {
  const [oldContactObject, setOldContactObject] = useState(useLoaderData());
  const [file, setFile] = useState(null);
  const [contactObject, setContactObject] = useState(oldContactObject);

  // useEffect(() => {
  //   setContactObject(contactObject);
  // }, [0]);

  const navigate = useNavigate();

  const UpdateContact = async () => {
    await editContact(contactObject);
    navigate("/admin");
  };

  const uploadFile = async () => {
    const filesFolderRef = ref(storage, `contactImages/${file.name}`);

    await uploadBytes(filesFolderRef, file);

    await getDownloadURL(filesFolderRef).then((URL) => {
      setContactObject({ ...contactObject, image: URL });
    });

    alert("Úspěšně nahráno");
  };

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const editContact = async () => {
    try {
      const contactDocRef = doc(db, "contacts", contactObject.id);

      await updateDoc(contactDocRef, {
        order: contactObject.order,
        important: contactObject.important,
        name: contactObject.name,
        role: contactObject.role,
        text: contactObject.text,
        email: contactObject.email,
        phone: contactObject.phone,
        team: contactObject.team,
        image: contactObject.image,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const DeleterWholePost = async () => {
    await deletePost();
    navigate("/admin");
  };

  const deletePost = async () => {
    const httpsReference = ref(storage, contactObject.image.toString());

    deleteObject(httpsReference);

    const contactRefDoc = doc(db, "contacts", contactObject.id);
    try {
      await deleteDoc(contactRefDoc);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <>
        <div className="d-flex align-items-center justify-content-between">
          <label className="me-3">Oddil</label>
          <select
            className="form-control rounded-0 w-75"
            defaultValue={oldContactObject.team}
            onChange={(e) =>
              setContactObject({
                ...contactObject,
                team: parseInt(e.target.value),
              })
            }
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
          </select>
        </div>
        <div className="d-flex mt-2 align-items-center justify-content-end">
          <label className="me-3">Important</label>
          <input
            className="form-control form-check-input rounded-0"
            type="checkbox"
            defaultChecked={oldContactObject.important}
            onChange={(e) =>
              setContactObject({
                ...contactObject,
                important: e.target.checked,
              })
            }
          />
        </div>

        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <label className="me-3">Name</label>
          <input
            className="form-control rounded-0 w-75"
            placeholder="Name..."
            defaultValue={oldContactObject.name}
            onChange={(e) =>
              setContactObject({ ...contactObject, name: e.target.value })
            }
          />
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <label className="me-3">Role</label>
          <input
            className="form-control rounded-0 w-75"
            placeholder="Role..."
            defaultValue={oldContactObject.role}
            onChange={(e) =>
              setContactObject({ ...contactObject, role: e.target.value })
            }
          />
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <label className="me-3">Email</label>
          <input
            className="form-control rounded-0 w-75"
            placeholder="Email..."
            defaultValue={oldContactObject.email}
            onChange={(e) =>
              setContactObject({ ...contactObject, email: e.target.value })
            }
          />
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <label className="me-3">Phone number</label>
          <input
            className="form-control rounded-0 w-75"
            placeholder="123 456 789"
            defaultValue={oldContactObject.phone}
            onChange={(e) =>
              setContactObject({ ...contactObject, phone: e.target.value })
            }
          />
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <label className="me-3">Change Profile picture</label>
          <div className="w-75 d-flex">
            <input
              className=" form-control rounded-0"
              type="file"
              multiple="multiple"
              accept="image/png, image/jpeg"
              onChange={onChangeFile}
            />
            <button
              className="btn-primary btn  input-group-text rounded-0"
              onClick={uploadFile}
            >
              Upload
            </button>
          </div>
        </div>
        {contactObject.image != "" ? (
          <>
            <img src={contactObject.image} className="w-100 mt-3" />
          </>
        ) : (
          <>No picture found</>
        )}

        <hr />

        <label className="me-3 mb-3">About</label>
        <A_TextEditor
          postObject={oldContactObject}
          setPostObject={setContactObject}
        ></A_TextEditor>

        {/* Create new post */}
        <hr />

        <A_Button
          functionOnClick={UpdateContact}
          text={"Úprava zprávy"}
          icon={"edit"}
        />
        <hr />
        <A_Button
          functionOnClick={DeleterWholePost}
          text={"Smazat příspěvek"}
          icon={"delete"}
          theme="danger"
        />
      </>

      <div className="mb-3"></div>
    </>
  );
}
