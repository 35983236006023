import { Grid, ImageListItem, ImageListItemBar } from "@mui/material";
import React from "react";

export default function ContactComponent({ contactDetails }) {
  return (
    <div
      className="flex-item text-center mx-auto p-3 mt-5"
      style={{
        minWidth: "300px",
        maxWidth: "320px",
      }}
    >
      <img
        src={contactDetails.image}
        style={{
          minWidth: "180px",
          maxWidth: "200px",
        }}
      />
      <h3 className="mt-3">{contactDetails.name}</h3>
      <span>{contactDetails.role}</span>
      <div
        className="mt-3 text-start"
        style={{ fontSize: "0.8rem" }}
        dangerouslySetInnerHTML={{ __html: contactDetails.text }}
      />
      <div style={{ fontSize: "0.9rem" }}>
        <div className="d-flex align-items-center" style={{ color: "#299b60" }}>
          <span className="me-4 material-symbols-outlined ">call</span>
          {contactDetails.phone}
        </div>
        <div
          className="d-flex align-items-center mt-2"
          style={{ color: "#8688B0" }}
        >
          <span className="me-4 material-symbols-outlined ">mail</span>
          <a
            className="text-decoration-none"
            style={{ color: "#8688B0" }}
            href={`mailto:${contactDetails.email}`}
          >
            {contactDetails.email}
          </a>
        </div>
      </div>
    </div>
  );
}
