import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { db, storage } from "../../database/firebase";
import A_TextEditor from "../../components/admin/A_TextEditor";
import A_Button from "../../components/admin/A_Button";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import A_PreCreateOverlay from "../../components/admin/A_PreCreateOverlay";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import A_DokumentyComponent from "../../components/admin/A_DokumentyComponent";

const addPost = async (postObject) => {
  try {
    const dateOfCreation = serverTimestamp();

    await addDoc(collection(db, "posts"), {
      created: dateOfCreation,
      title: postObject.title,
      text: postObject.text,
      team: postObject.team,
      type: postObject.type,
      pdfs: postObject.PDFURLs,
    });
  } catch (err) {
    console.log(err);
  }
};

const A_Create = () => {
  const [files, setFiles] = useState([]);
  const [postObject, setPostObject] = useState({
    title: "",
    text: "",
    team: 2,
    type: "",
    PDFURLs: [],
  });

  const removeFromList = (Doc) => {
    let newArray = postObject.PDFURLs;
    const index = newArray.indexOf(Doc);
    newArray.splice(index, 1);

    setPostObject({ ...postObject, PDFURLs: newArray });
  };

  const navigate = useNavigate();

  const createNewPost = async () => {
    await addPost(postObject);
    console.log(postObject);
    navigate("/admin");
  };

  const uploadFiles = () => {
    Array.from(files).forEach(async (file) => {
      const filesFolderRef = ref(storage, `postFiles/${file.name}`);

      await uploadBytes(filesFolderRef, file);

      await getDownloadURL(filesFolderRef).then((URL) => {
        let newArrayOfURLs = postObject.PDFURLs;
        newArrayOfURLs.push(URL);

        setPostObject({ ...postObject, PDFURLs: newArrayOfURLs });
      });
    });

    alert("Úspěšně nahráno");
  };

  return (
    <>
      {postObject.type != "" && postObject.team != null ? (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <label className="me-3">Oddíl</label>
            <select
              className="form-control rounded-0 w-75"
              defaultValue={postObject.team}
              onChange={(e) =>
                setPostObject({ ...postObject, team: parseInt(e.target.value) })
              }
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <label className="me-3">Typ</label>
            <select
              className="form-control rounded-0 w-75"
              defaultValue={postObject.type}
              onChange={(e) =>
                setPostObject({ ...postObject, type: e.target.value })
              }
            >
              <option value="aktuality">Aktuality</option>
              <option value="dokumenty">Dokumenty</option>
            </select>
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <label className="me-3">Titul</label>
            <input
              className="form-control rounded-0 w-75"
              placeholder="Titul..."
              onChange={(e) =>
                setPostObject({ ...postObject, title: e.target.value })
              }
            />
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <label className="me-3">Dokumenty (volitelný)</label>
            <div className="w-75 d-flex">
              <input
                className=" form-control rounded-0"
                type="file"
                multiple="multiple"
                accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, image/png, image/jpeg"
                onChange={(e) => setFiles(e.target.files)}
              />
              <button
                className="btn-primary btn  input-group-text rounded-0"
                onClick={uploadFiles}
              >
                Upload
              </button>
            </div>
          </div>
          <div className="ms-5">
            {postObject.PDFURLs.map((doc, index) => (
              <A_DokumentyComponent
                Doc={doc}
                removeFromList={removeFromList}
                key={index}
              />
            ))}
          </div>
          <hr />
          <label className="me-3 mb-3">Text</label>
          <A_TextEditor
            postObject={postObject}
            setPostObject={setPostObject}
          ></A_TextEditor>

          {/* Create new post */}
          <hr />

          {postObject.text == "" ? (
            <label className="text-bg-danger w-100 mb-2 ps-2">
              Je třeba přidat text
            </label>
          ) : (
            <></>
          )}
          {postObject.text != "" ? (
            <A_Button
              functionOnClick={createNewPost}
              text={"Zveřejnit příspěvek"}
              icon={"new_window"}
            />
          ) : (
            <A_Button
              text={"Zveřejnit příspěvek"}
              icon={"new_window"}
              disabled={true}
            />
          )}
        </>
      ) : (
        <>
          <A_PreCreateOverlay
            postObject={postObject}
            setPostObject={setPostObject}
          />
        </>
      )}

      <div className="mb-3"></div>
    </>
  );
};

export default A_Create;
