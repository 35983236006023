import { Outlet, useNavigate } from "react-router-dom";
import "./DefaultRootLayout.css";
import Navigationbar from "../components/Navigationbar";
import { auth } from "../database/firebase";
import A_Button from "../components/admin/A_Button";
import { signOut } from "firebase/auth";
import { useEffect } from "react";

const DefaultRootLayout = () => {
  const userAuth = auth.currentUser;
  const navigate = useNavigate();

  const logMeOut = async () => {
    try {
      await signOut(auth);

      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  const CheckForInactivity = () => {
    const expireTime = localStorage.getItem("expireTime");
    const user = auth.currentUser;

    if (expireTime < Date.now() && user) {
      console.log("Log Out!!!");
      logMeOut();
    }
  };

  // 300000 = 5 min

  const UpdateExpireTime = () => {
    const expireTime = Date.now() + 300000;

    localStorage.setItem("expireTime", expireTime);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      CheckForInactivity();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    UpdateExpireTime();

    window.addEventListener("click", UpdateExpireTime);
    window.addEventListener("keypress", UpdateExpireTime);
    window.addEventListener("scroll", UpdateExpireTime);
    window.addEventListener("mousemove", UpdateExpireTime);

    return () => {
      window.removeEventListener("click", UpdateExpireTime);
      window.removeEventListener("keypress", UpdateExpireTime);
      window.removeEventListener("scroll", UpdateExpireTime);
      window.removeEventListener("mousemove", UpdateExpireTime);
    };
  }, []);

  return (
    <main>
      <Navigationbar />

      <div style={{ minHeight: "100vh" }}>
        <Outlet />
      </div>
      <div
        className=" justify-content-center align-items-center d-flex"
        style={{
          height: "6vh",
          width: "100%",
          backgroundColor: "#8688b0",
          color: "#aaabc7",
        }}
      >
        <span>Created by:</span>
        <a
          style={{ color: "#aaabc7" }}
          className="ms-2"
          href="https://timvdaalen.com/"
          target="_blank"
        >
          Tim van Daalen
        </a>
      </div>
      {userAuth ? (
        <A_Button
          text="Logout"
          theme="secondary"
          icon="logout"
          functionOnClick={logMeOut}
        />
      ) : (
        <></>
      )}
    </main>
  );
};

export default DefaultRootLayout;
