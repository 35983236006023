import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { auth, db } from "../../database/firebase";

import { redirect, useLoaderData } from "react-router-dom";
import PostDisplay from "../../components/PostDisplay.js";
import ContactDisplay from "../../components/ContactDisplay.js";

export const OddilTwoKontaktyLoader = async () => {
  const user = auth.currentUser;

  if (
    (user?.uid != "jqYxa3JBgzRxcOPneiHxHtaOWH13" &&
      user?.uid != "TxwcMeIsZdfXRXf3PSGC1Cu8lRh2") ||
    !user
  ) {
    return redirect("/oddil_two/login");
  }

  try {
    const contactsCollectionRef = collection(db, "contacts");

    const q = query(
      contactsCollectionRef,
      where("team", "==", 2),
      where("important", "==", false),
      orderBy("order")
    );

    const q2 = query(
      contactsCollectionRef,
      where("team", "==", 2),
      where("important", "==", true),
      orderBy("order")
    );

    let combineData = { contact: [], Icontact: [] };
    const data = await getDocs(q);
    const data2 = await getDocs(q2);

    if (data != null) {
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      console.log(filteredData);

      combineData.contact = filteredData;
    }

    if (data2 != null) {
      const filteredData = data2.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      combineData.Icontact = filteredData;
    }

    return combineData;
  } catch (err) {
    console.error(err);
  }
};

export default function Kontakty() {
  const contacts = useLoaderData();
  return (
    <div>
      <h1 className="mb-5">Kontakty</h1>
      <ContactDisplay
        contacts={contacts.contact}
        Icontacts={contacts.Icontact}
      />
    </div>
  );
}
