import React from "react";

import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { auth, db } from "../../database/firebase";

import { redirect, useLoaderData } from "react-router-dom";
import PostDisplay from "../../components/PostDisplay.js";

export const OddilTwoDokumentyLoader = async () => {
  const user = auth.currentUser;

  if (
    (user?.uid != "jqYxa3JBgzRxcOPneiHxHtaOWH13" &&
      user?.uid != "TxwcMeIsZdfXRXf3PSGC1Cu8lRh2") ||
    !user
  ) {
    return redirect("/oddil_two/login");
  }
  try {
    const postsCollectionRef = collection(db, "posts");

    const q = query(
      postsCollectionRef,
      where("team", "==", 2),
      where("type", "==", "dokumenty"),
      orderBy("created", "desc")
    );

    const data = await getDocs(q);

    if (data != null) {
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      return filteredData;
    }

    return null;
  } catch (err) {
    console.error(err);
  }
};

export default function Dokumenty() {
  const posts = useLoaderData();
  return (
    <div>
      <h1 className="mb-5">Dokumenty</h1>
      <PostDisplay posts={posts} />
    </div>
  );
}
