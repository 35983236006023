import React from "react";
import { useEffect } from "react";
import A_ListOfPhotos from "./A_ListOfPhotos";
import A_Button from "./A_Button";
import { useNavigate } from "react-router-dom";

export default function A_ListOfPeriods({ Array }) {
  const navigate = useNavigate();

  return Array.map((period, index) => (
    <>
      <div className="d-flex justify-content-between mb-3 align-items-center">
        <h3>{period.timePeriod}</h3>
        <A_Button
          icon={"add"}
          functionOnClick={(e) => navigate("createphoto/" + period.id)}
          full={false}
        />
      </div>

      <A_ListOfPhotos Array={period.photos} />
      <hr />
    </>
  ));
}
