import React from "react";
import { useNavigate } from "react-router-dom";

export default function PhotoComponent({ photoDetails }) {
  const navigate = useNavigate();
  const goToPostPage = () => {
    window.open(photoDetails.linkTo, "_blank");
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url("${
            photoDetails.thumbnail ? photoDetails.thumbnail : "placeholder.webp"
          }")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "200px",
          cursor: "pointer",
        }}
        className="d-md-none d-flex justify-content-center align-items-end m-2 border rounded w-100"
        onClick={goToPostPage}
      >
        <span
          className="text-white  m-0 p-2 w-100 d-flex justify-content-center align-items-center rounded-bottom"
          style={
            photoDetails.summer
              ? {
                  backgroundColor: "#299b60",
                }
              : {
                  backgroundColor: "#8688B0",
                }
          }
        >
          <div className="w-100">{photoDetails.title}</div>
          {/* {photoDetails.summer ? (
            <small className="ms-2">(Letní tábor)</small>
          ) : (
            <></>
          )} */}
        </span>
      </div>
      <div
        style={{
          backgroundImage: `url("${
            photoDetails.thumbnail ? photoDetails.thumbnail : "placeholder.webp"
          }")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "200px",
          width: "23%",
          cursor: "pointer",
        }}
        className="d-md-flex d-none justify-content-center align-items-end m-2 border rounded"
        onClick={goToPostPage}
      >
        <span
          className=" text-white m-0 p-2 w-100 d-flex justify-content-center align-items-center rounded-bottom"
          style={
            photoDetails.summer
              ? {
                  backgroundColor: "#299b60",
                }
              : {
                  backgroundColor: "#8688B0",
                }
          }
        >
          <div className="w-100">{photoDetails.title}</div>
        </span>
      </div>
    </>
  );
}
