import React from "react";

export default function Onas() {
  return (
    <div>
      <div className="d-flex flex-lg-nowrap flex-wrap justify-content-center">
        <div className="me-5" style={{ fontSize: "0.9rem" }}>
          <h1 className="mb-3">O nás</h1>

          <p>
            MANERE je společenství pro děti a mládež, které se věnuje aktivitám
            v přírodě se snahou o podporu přirozeného způsobu života.
            Pojmenování našeho společenství MANERE jsme začali používat při
            jednom vysokohorském putování a pochází z latinského přísloví
          </p>
          <div className="w-100 d-flex justify-content-between align-items-center mb-2">
            <div
              className="w-100"
              style={{ height: "1px", backgroundColor: "#299B60" }}
            />
            <h4 className="text-nowrap mx-4" style={{ color: "#8688B0" }}>
              „Manere in montibus“
            </h4>
            <div
              className="w-100"
              style={{ height: "1px", backgroundColor: "#299B60" }}
            />
          </div>
          <p>
            volně přeloženo „pobývat na horách, na výšinách ducha“. Tradice naší
            činnosti trvá bez přerušení od 13. října 1978, kdy vznikl náš
            nejstarší oddíl Stopaři. Zpočátku jsme měli klubovnu v Brně –
            Jundrově, v roce 2005 jsme přesídlili do Bílovic nad Svitavou. Z
            historických důvodů máme právní subjektivitu od roku 1991, z
            právního hlediska patříme mezi nestátní neziskové organizace
            (zapsaný spolek). Naše činnost má řadu společných myšlenkových a
            organizačních prvků s českým skautingem. Oddíly našeho společenství
            za dobu jeho existence prošlo několik stovek dětí a dospívajících v
            sedmi generacích, z nichž mnozí si vytvořená přátelství zachovali i
            ve svém dalším životě. Náš metodický systém je vypracován pro tři
            věkové kategorie: Skřítci (6 - 9 let), Stopaři (10 - 14 let) a
            Maneráci (15+ let). Zpravidla 8 až 10 skřítků nebo stopařů tvoří
            družinu, kterou vedou dva rádci starších 15-ti let. Vedoucím oddílu
            je samozřejmě dospělá osoba.
          </p>
          <p>
            Základní podmínkou pro členství je dostatečná účast na oddílových
            podnicích, formálně pak písemná přihláška s podpisem zákonných
            zástupců. Činnost spolku je financována především z členských
            příspěvků doplněných sponzorskými dary a v menší míře z jiných
            zdrojů. Za organizací a vedením činnosti stojí tým dobrovolníků
            (náklady na činnost jsou proto minimální), z nichž někteří mají
            dlouholetou praxi s volnočasovými aktivitami dětí a odpovídající
            vzdělání.
          </p>
          <p>
            Aktivity v přírodě, kterým se věnujeme, tvoří především táboření,
            pěší turistika, sjíždění řek na kánoích a nafukovacích lodích, v
            menší míře pak cykloturistika a lyžování na běžkách. Starší členové
            se také věnují základům lezení a vysokohorské turistice. To vše se
            snahou o porozumění přírodě, která je neoddělitelnou součástí celé
            naší činnosti.
          </p>
          <p>
            Čas potřebný pro účast na běžné činnosti zahrnuje jednou týdně
            družinovou nebo oddílovou schůzku (2 hodiny) v naší klubovně nebo v
            přírodě, zpravidla jednou měsíčně výpravu (jedno nebo vícedenní) a v
            létě letní tábor. Starší členové pak mívají program časově trochu
            náročnější.
          </p>
        </div>
        <div
          className="text-nowrap text-black w-100"
          style={{ fontSize: "0.9rem" }}
          text-black
        >
          <h5>Kontakt</h5>
          <div
            className="w-100 rounded p-4 mt-4"
            style={{ backgroundColor: "#8688B066" }}
          >
            <h5>Vedoucí Oddílu</h5>
            <span>Tereza Svobodová</span>
            <div className="mb-4">
              <div className="d-flex mt-3 align-items-center">
                <span
                  className="me-4 material-symbols-outlined "
                  style={{ color: "#8688B066" }}
                >
                  call
                </span>
                603 501 506
              </div>
              <div className="d-flex align-items-center mt-2">
                <span
                  className="me-4 material-symbols-outlined "
                  style={{ color: "#8688B066" }}
                >
                  mail
                </span>
                <a
                  className="text-decoration-none text-black"
                  s
                  href={`mailto:svobodova.tereza34@gmail.com`}
                >
                  svobodova.tereza34@gmail.com
                </a>
              </div>
            </div>
            <h5>Zástupce vedoucího oddílu</h5>
            <span>Přemysl Šoupal</span>
            <div className="mb-4">
              <div className="d-flex mt-3 align-items-center">
                <span
                  className="me-4 material-symbols-outlined "
                  style={{ color: "#8688B066" }}
                >
                  call
                </span>
                775 145 986
              </div>
              <div className="d-flex align-items-center mt-2">
                <span
                  className="me-4 material-symbols-outlined "
                  style={{ color: "#8688B066" }}
                >
                  mail
                </span>
                <a
                  className="text-decoration-none text-black"
                  s
                  href={`mailto:prema.soupal@seznam.cz`}
                >
                  prema.soupal@seznam.cz
                </a>
              </div>
            </div>
            <h5>Oddilova adresa</h5>
            <div>
              <div className="d-flex align-items-center mt-2">
                <span
                  className="me-4 material-symbols-outlined "
                  style={{ color: "#8688B066" }}
                >
                  mail
                </span>
                <a
                  className="text-decoration-none text-black"
                  s
                  href={`mailto:manere1978@gmail.com`}
                >
                  manere1978@gmail.com
                </a>
              </div>
            </div>
          </div>
          {/* ---------- */}
          <div
            className="w-100 rounded p-4 mt-1 "
            style={{ backgroundColor: "#8688B066" }}
          >
            <h5>Sídlo spolku</h5>
            <p>
              MANERE, z.s.
              <br />
              Husova 492 <br /> 664 01 Bílovice nad Svitavou
            </p>
            <ul class="list-inline">
              <li class="list-item">IČO/DIČ: 188 244 55 / CZ18824455</li>
              <li class="list-item">Bankovní účet: 200 116 2967 / 2010</li>
              <li class="list-item">Vedoucí spolku: Jonáš Retek</li>
            </ul>

            <div>
              <div className="d-flex mt-3 align-items-center">
                <span
                  className="me-4 material-symbols-outlined "
                  style={{ color: "#8688B066" }}
                >
                  call
                </span>
                773 206 138
              </div>
              <div className="d-flex align-items-center mt-2">
                <span
                  className="me-4 material-symbols-outlined "
                  style={{ color: "#8688B066" }}
                >
                  mail
                </span>
                <a
                  className="text-decoration-none text-black"
                  s
                  href={`mailto:jon.retek@seznam.cz`}
                >
                  jon.retek@seznam.cz
                </a>
              </div>
            </div>
          </div>
          {/* ---------- */}
          <div
            className="w-100 rounded p-4 mt-1 "
            style={{ backgroundColor: "#8688B066" }}
          >
            <h5>Klubovna</h5>
            <ul class="list-inline">
              <li class="list-item">Za Nádražím 134</li>
              <li class="list-item">664 01 Bílovice nad Svitavou</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mt-3" style={{ fontSize: "0.9rem" }}>
        <h1 className="mt-5">Naše klubovna</h1>
        <p>
          Klubovnu tvoří dřevěná chatka v blízkosti bílovického nádraží s
          přilehlým pozemkem, na kterém je travnaté hřiště, venkovní posezení s
          dřevěnými stoly a lavicemi a terasa s ohništěm. V klubovně je vstupní
          předsíň s kuchyňským koutem, která zároveň slouží jako šatna, může se
          zde u jednoho stolu také sejít menší družina. Vlastní klubovní
          místnost je největší pro pohodlnou schůzku dvou družin. V chatce jsou
          ještě dva sklady. Když jsme se zde v roce 2006 přesunuli z předchozí
          klubovny, byla to zanedbaná dřevěná chatka na zarostlé zahradě
          bývalých školních pozemků. Postupně jsme na pozemku provedli terénní a
          zahradní úpravy, obnovili oplocení, do chatky přivedli elektrickou
          energii a vybavili ji elektroinstalací, postavili komín a kamna,
          vyměnili okna a dveře, obnovili nátěry, přidali zastřešení vstupu,
          obložili jsme palubkami předsíň, spravili podlahy, zateplili strop ve
          všech místnostech a provedli řadu dalších vylepšení. Bylo to možné
          jenom díky práci rodičů a členů našeho spolku (většinou realizované
          formou pravidelných velkých podzimních brigád), finanční pomoci dvou
          krajských dotací a v posledních letech díky finanční pomoci obce
          Bílovice nad Svitavou. V postupných úpravách, ale už menšího rozsahu,
          pokračujeme i nadále.
        </p>
        <div className="d-flex mt-5 flex-xl-nowrap flex-wrap">
          <div className="me-5" style={{ minWidth: "300px" }}>
            <h3>Jak se k nám dostat?</h3>
            <ol>
              <li>
                Od vlakového nádraží směr Brno po asfaltovém chodníčku (ulička
                Nad Nádražím) k místu, kde se železniční trať přiblíží k
                chodníku a skončí oplocení po pravé straně. Zde odbočit vpravo a
                po cca 20-ti metrech za pumpou je vstupní branka (ulička Za
                Nádražím) do areálu zahrady klubovny.
              </li>
              <h5 className="my-3">nebo</h5>
              <li>
                Z ulice Jiráskova v její jižní části sejít po schodišti na
                uličku Nad Nádražím, po ní pokračovat doprava a dále dle
                předchozího popisu.
              </li>
            </ol>
          </div>
          <img
            className="w-100"
            src="https://firebasestorage.googleapis.com/v0/b/manere-db48c.appspot.com/o/Website%20assets%2Fmap.png?alt=media&token=5ad54719-62d5-46d9-9358-e1611829a39f"
            alt="map"
          />
        </div>
      </div>
      <div id="carouselExample" class="carousel slide mt-4">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/manere-db48c.appspot.com/o/Website%20assets%2Fklubovna1.jpg?alt=media&token=f11908d9-dabf-4cad-96d9-e67ce3d6d6aa"
              class="d-block w-100"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/manere-db48c.appspot.com/o/Website%20assets%2Fpohled-ke-vstupu.jpg?alt=media&token=82ba2b5c-1739-422a-8933-b2f795d5dbf0"
              class="d-block w-100"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/manere-db48c.appspot.com/o/Website%20assets%2Fpohled-od-vstupu.jpg?alt=media&token=cd563a18-9b0c-4395-8e79-34ba3bb21390"
              class="d-block w-100"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/manere-db48c.appspot.com/o/Website%20assets%2Fpred-klubovnou.jpg?alt=media&token=d7bb32bd-b2ef-4963-8aac-e3c0f6689c68"
              class="d-block w-100"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/manere-db48c.appspot.com/o/Website%20assets%2Fpredsin.jpg?alt=media&token=87372b33-b8ef-459e-9758-17f65778c079"
              class="d-block w-100"
              alt="..."
            />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="prev"
        >
          <div className=" bg-black px-3 pt-3 pb-2">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </div>
        </button>
        <button
          class="carousel-control-next "
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
        >
          <div className=" bg-black px-3 pt-3 pb-2">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </div>
        </button>
      </div>
      <h3 className="mt-4 mb-0">Dokumenty</h3>
      <div className="d-flex w-100 flex-wrap flex-lg-nowrap ">
        <a
          className="p-3 d-flex text-white  text-decoration-none justify-content-between w-100 mt-2   flex-nowrap rounded"
          style={{ backgroundColor: "#8688B0" }}
          href="https://firebasestorage.googleapis.com/v0/b/manere-db48c.appspot.com/o/Website%20assets%2Fprihlaska-ev.list_2023.docx?alt=media&token=549a5d76-42d0-4a0c-be7e-5f3406656377"
          target="_blank"
        >
          <div>Přihláška</div>
          <span className="material-symbols-outlined">download</span>
        </a>
        <div className="me-2"></div>
        <a
          className="p-3 d-flex text-white  text-decoration-none justify-content-between w-100 mt-2 flex-nowrap rounded"
          style={{ backgroundColor: "#8688B0" }}
          href="https://firebasestorage.googleapis.com/v0/b/manere-db48c.appspot.com/o/Website%20assets%2FStanovy%20Manere.pdf?alt=media&token=dd7172e2-9966-429d-bed2-e9fe1dec78b3"
          target="_blank"
        >
          <div>Stanovy Manere</div>

          <span className="material-symbols-outlined">download</span>
        </a>
      </div>
    </div>
  );
}
