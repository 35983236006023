import React from "react";
import PostComponent from "./PostComponent";
import PhotoComponent from "./PhotoComponent";
import A_Button from "./admin/A_Button";
import { useState } from "react";
import { useEffect } from "react";
import { db } from "../database/firebase";
import { doc, getDoc } from "firebase/firestore";

export default function PhotoDisplay({ Array }) {
  const [arrayOfPhotoData, setArrayOfPhotoData] = useState([]);
  useEffect(() => {
    ListOfPhotos();
  }, [0]);

  const ListOfPhotos = () => {
    setArrayOfPhotoData([]);
    Array.forEach(async (photoId) => {
      try {
        const photoRefDoc = doc(db, "photos", photoId);

        const photoDoc = await getDoc(photoRefDoc);

        const filteredData = { ...photoDoc.data(), id: photoDoc.id };

        setArrayOfPhotoData((arrayOfPhotoData) => [
          ...arrayOfPhotoData,
          filteredData,
        ]);
      } catch (err) {
        console.error(err);
      }
    });
  };

  return (
    <div className="d-flex flex-wrap">
      {arrayOfPhotoData.map((photo, index) => (
        <PhotoComponent photoDetails={photo} />
      ))}
    </div>
  );
}
