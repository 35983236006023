import "./Home.css";

const Home = () => {
  return (
    <div>
      <div className="homepage_image w-100">
        <div className="overlay"></div>
        <p className="d-none d-md-inline" style={{ zIndex: "4" }}>
          Vítáme všechny návštěvníky - naše současné a dřívější členy, jejich
          rodiče, kamarády, příznivce i zájemce o členství - na našich webových
          stránkách. Stránky jsou rozděleny na dvě sekce. Pro veřejnost obsahuje
          základní informace o našem zapsaném spolku a kontaktní údaje. Sekce
          Pro členy je určena našim členům a jejich rodičům a je přístupná
          po zadání hesla.
        </p>

        <p className="d-md-none d-inline" style={{ zIndex: "4" }}>
          <p style={{ fontSize: "1rem" }} className="m-0">
            Vítáme všechny návštěvníky - naše současné a dřívější členy, jejich
            rodiče, kamarády, příznivce i zájemce o členství - na našich
            webových stránkách. Stránky jsou rozděleny na dvě sekce. Pro
            veřejnost obsahuje základní informace o našem zapsaném spolku a
            kontaktní údaje. Sekce Pro členy je určena našim členům a jejich
            rodičům a je přístupná po zadání hesla.
          </p>
        </p>
      </div>
    </div>
  );
};

export default Home;
