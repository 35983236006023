import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="d-flex justify-content-center mt-5">
      <ul className="list-inline ">
        <h1>1. Oddíl</h1>
        <li>
          <Link to="../kontakty">Kontakty</Link>
        </li>
        <li>
          <Link to="../aktuality">Aktuality</Link>
        </li>
        <li>
          <Link to="../dokumenty">Dokumenty</Link>
        </li>
      </ul>
    </div>
  );
}

export default Home;
