import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { db, storage } from "../../database/firebase";
import A_TextEditor from "../../components/admin/A_TextEditor";
import A_Button from "../../components/admin/A_Button";
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import A_PreCreateOverlayContact from "../../components/admin/A_PreCreateOverlayContact";

const addPeriod = async (photoObject) => {
  try {
    const dateOfCreation = serverTimestamp();

    await addDoc(collection(db, "periods"), {
      created: dateOfCreation,
      timePeriod: photoObject.timePeriod,
      photos: [],
    });
  } catch (err) {
    console.error(err);
  }
};

const A_CreatePeriod = () => {
  const [periodObject, setPeriodObject] = useState({
    timePeriod: "",
  });

  const navigate = useNavigate();

  const createNewPhoto = async () => {
    await addPeriod(periodObject);
    navigate("/admin");
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <label className="me-3">Period</label>
        <input
          className="form-control rounded-0 w-75"
          placeholder="2000/2001"
          onChange={(e) =>
            setPeriodObject({ ...periodObject, timePeriod: e.target.value })
          }
        />
      </div>

      {/* Create new post */}
      <hr />
      {periodObject.timePeriod != "" ? (
        <A_Button
          functionOnClick={createNewPhoto}
          text={"Zveřejněte odkaz na fotku"}
          icon={"new_window"}
        />
      ) : (
        <A_Button
          text={"Zveřejněte odkaz na fotku"}
          icon={"new_window"}
          disabled={true}
        />
      )}
    </>
  );
};

export default A_CreatePeriod;
