import { useNavigate } from "react-router-dom";
import A_Button from "./A_Button";

export default function A_ListOfContacts({ Array1, Array2 }) {
  const navigate = useNavigate();

  return (
    <div>
      <h3>1 Oddil</h3>
      <div className="row mx-0 mt-3 row-auto">
        <div className="col-1 bg-light p-1 ps-2">#</div>
        <div className="col-1 bg-light ms-2 p-1 ps-2">!</div>
        <div className="col-4 bg-light ms-2 p-1 ps-2">Name</div>
        <div className="col bg-light ms-2 p-1 ps-2">Role</div>
      </div>

      {Array1.length != 0 ? (
        <>
          {Array1.map((contact, index) => (
            <div key={index} className=" row row-auto mx-0 my-2">
              <div className="col-1 border p-1 ps-2">{contact.order}</div>
              <div className="col-1 border ms-2 p-1 ps-2">
                {contact.important ? "x" : ""}
              </div>

              <div className="col-4 border ms-2 p-1 ps-2">{contact.name}</div>
              <div className="col border ms-2 p-1 ps-2">{contact.role}</div>
              <div className="col col-auto ms-2 p-0 border ">
                <A_Button
                  icon={"edit"}
                  functionOnClick={(e) =>
                    navigate("edit/contact/" + contact.id)
                  }
                />
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <h1>EMPTY</h1>
        </>
      )}
      <hr />
      <h3>2 Oddil</h3>
      <div className="row mx-0 mt-3 row-auto">
        <div className="col-1 bg-light p-1 ps-2">#</div>
        <div className="col-1 bg-light ms-2 p-1 ps-2">!</div>
        <div className="col-4 bg-light ms-2 p-1 ps-2">Name</div>
        <div className="col bg-light ms-2 p-1 ps-2">Role</div>
      </div>

      {Array2.length != 0 ? (
        <>
          {Array2.map((contact, index) => (
            <div key={index} className=" row row-auto mx-0 my-2">
              <div className="col-1 border p-1 ps-2">{contact.order}</div>
              <div className="col-1 border ms-2 p-1 ps-2">
                {contact.important ? "x" : ""}
              </div>
              <div className="col-4 border ms-2 p-1 ps-2">{contact.name}</div>
              <div className="col border ms-2 p-1 ps-2">{contact.role}</div>
              <div className="col col-auto ms-2 p-0 border ">
                <A_Button
                  icon={"edit"}
                  functionOnClick={(e) =>
                    navigate("edit/contact/" + contact.id)
                  }
                />
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <h1>EMPTY</h1>
        </>
      )}
    </div>
  );
}
