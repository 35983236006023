import { useState } from "react";
import "./Navigationbar.css";
import BasicMenu from "./BasicMenu";
import { Link } from "react-router-dom";

const Navigationbar = () => {
  const [hamburger, setHamburger] = useState(false);

  return (
    <div className="fixed-top nav ">
      <div className="d-flex justify-content-between w-100 my-3 px-md-5 px-3 ">
        <Link to="/" style={{ marginBottom: "-60px" }}>
          <img
            style={{
              height: "100px",
              width: "100px",
              position: "relative",
              zIndex: "1",
            }}
            src="https://firebasestorage.googleapis.com/v0/b/manere-db48c.appspot.com/o/Website%20assets%2Fmanere%20logo.png?alt=media&token=59475e08-8acd-43d3-80f0-4a016c78696e&_gl=1*1qxezkw*_ga*MjUxMjA4ODgyLjE2ODc4NTg5MTA.*_ga_CW55HF8NVT*MTY5ODAxMjkyMC44MS4xLjE2OTgwMTI5NzguMi4wLjA."
            alt="logo manere"
          />
        </Link>
        <h5 className="mt-2 mb-0 d-md-flex d-none">
          <BasicMenu
            title={"Pro veřejnost"}
            links={[
              { title: "O nás", link: "pro_verejnost/o_nas" },
              {
                title: "Pro zájemce o členství",
                link: "pro_verejnost/pro_Zaljemece_o_clenstvi",
              },
            ]}
            setHamburger={setHamburger}
          />
          {/* <BasicMenu
            title={"1. oddíl"}
            links={[
              { title: "Kontakty", link: "oddil_one/kontakty" },
              { title: "Aktuality", link: "oddil_one/aktuality" },
              { title: "Dokumenty", link: "oddil_one/dokumenty" },
            ]}
            setHamburger={setHamburger}
          /> */}
          <BasicMenu
            title={"Oddíl"}
            links={[
              { title: "Kontakty", link: "oddil_two/kontakty" },
              { title: "Aktuality", link: "oddil_two/aktuality" },
              { title: "Dokumenty", link: "oddil_two/dokumenty" },
            ]}
            setHamburger={setHamburger}
          />
          <Link
            className="text-decoration-none text-white me-2 btn text-white rounded-0 border-0"
            to="/fotogalerie"
          >
            Fotogalerie
          </Link>
        </h5>
        <button
          className="btn d-md-none d-inline p-0 pt-1 pb-0"
          onClick={() => {
            setHamburger(!hamburger);
          }}
        >
          {hamburger ? (
            <span
              className="text-white material-symbols-outlined "
              style={{ fontSize: "30px" }}
            >
              close
            </span>
          ) : (
            <span
              class="text-white  material-symbols-outlined"
              style={{ fontSize: "30px" }}
            >
              menu
            </span>
          )}
        </button>
      </div>
      {hamburger ? (
        <ul className="list-group w-100 px-3 hamburger d-md-none d-inline">
          <hr />
          <li className="d-flex justify-content-end">
            <BasicMenu
              title={"Pro veřejnost"}
              links={[
                { title: "O nás", link: "pro_verejnost/o_nas" },
                {
                  title: "Pro zájemce o členství",
                  link: "pro_verejnost/pro_Zaljemece_o_clenstvi",
                },
              ]}
              setHamburger={setHamburger}
            />
          </li>

          <hr />
          <li className="d-flex justify-content-end">
            {/* <BasicMenu
              title={"1. oddíl"}
              links={[
                { title: "Kontakty", link: "oddil_one/kontakty" },
                { title: "Aktuality", link: "oddil_one/aktuality" },
                { title: "Dokumenty", link: "oddil_one/dokumenty" },
              ]}
              setHamburger={setHamburger}
            /> */}
          </li>
          <hr />
          <li className="d-flex justify-content-end">
            <BasicMenu
              title={"Oddíl"}
              links={[
                { title: "Kontakty", link: "oddil_two/kontakty" },
                { title: "Aktuality", link: "oddil_two/aktuality" },
                { title: "Dokumenty", link: "oddil_two/dokumenty" },
              ]}
              setHamburger={setHamburger}
            />
          </li>
          <hr />
          <li className="d-flex justify-content-end">
            <Link
              className="text-decoration-none text-white me-2"
              to="/fotogalerie"
              onClick={() => setHamburger(false)}
            >
              Fotogalerie
            </Link>
          </li>
          <hr />
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Navigationbar;
