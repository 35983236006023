import { Link } from "react-router-dom";

export default function BasicMenu({ title, links, setHamburger }) {
  return (
    <div>
      <div className="dropdown">
        <button
          className="btn text-white rounded-0 border-0"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {title}
        </button>

        <ul className="dropdown-menu rounded shadow ">
          {links.map((link, index) => (
            <li key={index}>
              <Link
                onClick={() => setHamburger(false)}
                className="dropdown-item m-0 pt-4"
                to={link.link}
              >
                {link.title}
                <hr />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
