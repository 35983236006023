import React from "react";
import { useNavigate } from "react-router-dom";
import A_Button from "./A_Button";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../database/firebase";
import { useState } from "react";
import { useEffect } from "react";

export default function A_ListOfPhotos({ Array }) {
  const navigate = useNavigate();
  const [arrayOfPhotoData, setArrayOfPhotoData] = useState([]);

  useEffect(() => {
    ListOfPhotos();
  }, [0]);

  const ListOfPhotos = () => {
    setArrayOfPhotoData([]);
    Array.forEach(async (photoId) => {
      try {
        const photoRefDoc = doc(db, "photos", photoId);

        const photoDoc = await getDoc(photoRefDoc);

        const filteredData = { ...photoDoc.data(), id: photoDoc.id };

        setArrayOfPhotoData((arrayOfPhotoData) => [
          ...arrayOfPhotoData,
          filteredData,
        ]);
      } catch (err) {
        console.error(err);
      }
    });
  };

  return (
    <div>
      <div className="row mx-0 row-auto">
        <div className="col-1 bg-light p-1 ps-2">#</div>
        <div className="col bg-light ms-2 p-1 ps-2">Titul</div>
      </div>

      {arrayOfPhotoData != null ? (
        <>
          {arrayOfPhotoData.map((post, index) => (
            <div
              key={index}
              className=" row row-auto mx-0 my-2"
              style={post.summer ? { borderColor: "#333" } : {}}
            >
              <div className="col-1 border p-1 ps-2">{index}</div>
              <div className="col border ms-2 p-1 ps-2">
                {post.title} {post.summer ? "(Letní tábor)" : ""}
              </div>
              <div className="col col-auto ms-2 p-0  ">
                <A_Button
                  icon={"open_in_new"}
                  functionOnClick={(e) => window.open(post.linkTo, "_blank")}
                />
              </div>
              <div className="col col-auto ms-2 p-0">
                <A_Button
                  icon={"edit"}
                  functionOnClick={(e) => navigate("edit/photo/" + post.id)}
                />
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <h1>EMPTY</h1>
        </>
      )}
    </div>
  );
}
