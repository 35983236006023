import { useLoaderData } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { db, storage } from "../../database/firebase";
import A_TextEditor from "../../components/admin/A_TextEditor";
import A_Button from "../../components/admin/A_Button";
import {
  addDoc,
  arrayRemove,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import A_PreCreateOverlayContact from "../../components/admin/A_PreCreateOverlayContact";
import { useEffect } from "react";

export default function A_EditPhoto() {
  const [oldPhotoObject, setOldPhotoObject] = useState(useLoaderData());
  const [file, setFile] = useState(null);
  const [photoObject, setPhotoObject] = useState(oldPhotoObject);

  const navigate = useNavigate();

  const UpdatePhoto = async () => {
    await editPhoto(photoObject);
    navigate("/admin");
  };

  const uploadFile = async () => {
    const filesFolderRef = ref(storage, `contactImages/${file.name}`);

    await uploadBytes(filesFolderRef, file);

    await getDownloadURL(filesFolderRef).then((URL) => {
      setPhotoObject({ ...photoObject, thumbnail: URL });
    });

    alert("Úspěšně nahráno");
  };

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const editPhoto = async () => {
    try {
      const photoDocRef = doc(db, "photos", photoObject.id);

      await updateDoc(photoDocRef, {
        title: photoObject.title,
        linkTo: photoObject.linkTo,
        thumbnail: photoObject.thumbnail,
        summer: photoObject.summer,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const DeleterWholePhoto = async () => {
    await deletePhoto();
    navigate("/admin");
  };

  const deletePhoto = async () => {
    const photoRefDoc = doc(db, "photos", photoObject.id);
    try {
      const periodDocRef = doc(db, "periods", photoObject.periodId);

      await updateDoc(periodDocRef, {
        photos: arrayRemove(photoObject.id.toString()),
      });

      await deleteDoc(photoRefDoc);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <label className="me-3">Titul</label>
        <input
          className="form-control rounded-0 w-75"
          placeholder="Titul..."
          defaultValue={oldPhotoObject.title}
          onChange={(e) =>
            setPhotoObject({ ...photoObject, title: e.target.value })
          }
        />
      </div>
      <hr />
      <div className="d-flex align-items-center justify-content-between">
        <label className="me-3">Adresa URL Disku Google</label>
        <input
          className="form-control rounded-0 w-75"
          placeholder="URL..."
          defaultValue={oldPhotoObject.linkTo}
          onChange={(e) =>
            setPhotoObject({ ...photoObject, linkTo: e.target.value })
          }
        />
      </div>
      <hr />
      <div className="d-flex align-items-center justify-content-between">
        <label className="me-3">Náhled</label>
        <div className="w-75 d-flex">
          <input
            className=" form-control rounded-0"
            type="file"
            multiple="multiple"
            accept="image/png, image/jpeg"
            onChange={onChangeFile}
          />
          <button
            className="btn-primary btn  input-group-text rounded-0"
            onClick={uploadFile}
          >
            Upload
          </button>
        </div>
      </div>
      <hr />
      <div className="d-flex mt-2 align-items-center justify-content-between">
        <label className="me-3">Letní tábor</label>
        <input
          className="form-control form-check-input rounded-0"
          type="checkbox"
          defaultChecked={oldPhotoObject.summer}
          onChange={(e) =>
            setPhotoObject({
              ...photoObject,
              summer: e.target.checked,
            })
          }
        />
      </div>
      <hr />
      <A_Button
        functionOnClick={UpdatePhoto}
        text={"Úprava zprávy"}
        icon={"edit"}
      />
      <hr />
      <A_Button
        functionOnClick={DeleterWholePhoto}
        text={"Smazat příspěvek"}
        icon={"delete"}
        theme="danger"
      />

      <div className="mb-3"></div>
    </>
  );
}
