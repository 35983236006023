import React from "react";
import { useState } from "react";
import A_Button from "./A_Button";
import A_TextEditor from "./A_TextEditor";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../database/firebase";
import { useEffect } from "react";

const updateDate = async (oddilOneAgenda, oddilTwoAgenda) => {
  try {
    const dateOfCreation = serverTimestamp();

    await updateDoc(doc(db, "agenda", "rBnTZhFLV2OhEle4L9uW"), {
      created: dateOfCreation,
      teamOne: oddilOneAgenda.text,
      teamTwo: oddilTwoAgenda.text,
    });
  } catch (err) {
    console.log(err);
  }
};

const getData = async () => {};

export default function A_Agenda() {
  const [openAgenda, setOpenAgenda] = useState(false);

  const [agendaObject, setAgendaObject] = useState(getData);

  const [oddilOneAgenda, setOddilOneAgenda] = useState({
    text: "",
  });
  const [oddilTwoAgenda, setOddilTwoAgenda] = useState({ text: "" });

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>vložit novou agendu</div>
        <A_Button
          icon="calendar_month"
          full={false}
          functionOnClick={() => setOpenAgenda(!openAgenda)}
        />
      </div>
      {openAgenda ? (
        <div className="mb-3">
          <h4>1. Oddíl</h4>
          <A_TextEditor
            postObject={oddilOneAgenda}
            setPostObject={setOddilOneAgenda}
          />
          <hr />
          <h4>2. Oddíl</h4>
          <A_TextEditor
            postObject={oddilTwoAgenda}
            setPostObject={setOddilTwoAgenda}
          />
          <hr />
          <A_Button
            icon="edit"
            text="aktualizovat agendu"
            functionOnClick={() => updateDate(oddilOneAgenda, oddilTwoAgenda)}
          />
          <hr />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
