import "./App.css";
import {
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
  useNavigate,
} from "react-router-dom";

//Layouts
import DefaultRootLayout from "./layouts/DefaultRootLayout";
import AdminDefaultRootLayout from "./layouts/AdminDefaultRootLayout";
import Oddil_OneRootLayout from "./layouts/Oddil_OneRootLayout.js";
import Oddil_TwoRootLayout from "./layouts/Oddil_TwoRootLayout.js";
import FotogalerieRootLayout from "./layouts/FotogalerieRootLayout.js";

//Pages
import Home from "./pages/Home";
import Fotogalarie, { FotoLoader } from "./pages/Fotogalarie.js";
import Login_Foto from "./pages/Login.js";

//Pages - Folder: ProVerejnost
import Onas from "./pages/ProVerejnost/Onas.js";
import Prozajemeoclevnstvi from "./pages/ProVerejnost/Prozajemeoclevnstvi.js";

//Pages - Folder: Oddil_One
import Home_One from "./pages/Oddil_One/Home.js";
import Login_one from "./pages/Oddil_One/Login.js";
import Kontakty_One, {
  OddilOneKontaktyLoader,
} from "./pages/Oddil_One/Kontakty.js";
import Aktuality_One, {
  OddilOneAktualityLoader,
} from "./pages/Oddil_One/Aktuality.js";
import Dokumenty_One, {
  OddilOneDokumentyLoader,
} from "./pages/Oddil_One/Dokumenty.js";

//Pages - Folder: Oddil_Two
import Home_Two from "./pages/Oddil_Two/Home.js";
import Login_Two from "./pages/Oddil_Two/Login.js";
import Kontakty_Two, {
  OddilTwoKontaktyLoader,
} from "./pages/Oddil_Two/Kontakty.js";
import Aktuality_Two, {
  OddilTwoAktualityLoader,
} from "./pages/Oddil_Two/Aktuality.js";
import Dokumenty_Two, {
  OddilTwoDokumentyLoader,
} from "./pages/Oddil_Two/Dokumenty.js";

//Admin Pages
import A_Home from "./pages/admin/A_Home";
import A_Create from "./pages/admin/A_Create";
import A_Edit from "./pages/admin/A_Edit";
import A_Login from "./pages/admin/A_Login";

import { contactLoader, photoLoader, postLoader } from "./database/crud";
import { postsLoader } from "./pages/admin/A_Home";
import PostPage from "./pages/PostPage.js";
import A_CreateContact from "./pages/admin/A_CreateContact.js";
import A_EditContact from "./pages/admin/A_EditContact.js";
import A_CreatePhoto from "./pages/admin/A_CreatePhoto.js";
import A_EditPhoto from "./pages/admin/A_EditPhoto.js";
import A_CreatePeriod from "./pages/admin/A_CreatePeriod.js";
import ProVerejnostRootLayout from "./layouts/ProVerejnostRootLayout.js";
import { auth } from "./database/firebase.js";
import { useEffect, useState } from "react";
import userEvent from "@testing-library/user-event";

function App() {
  const router = createHashRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route element={<DefaultRootLayout />}>
          {/* Home page */}
          <Route index element={<Home />}></Route>
          {/* --- */}
          {/* For Public */}
          <Route path="pro_verejnost" element={<ProVerejnostRootLayout />}>
            <Route path="o_nas" element={<Onas />}></Route>
            <Route
              path="pro_Zaljemece_o_clenstvi"
              element={<Prozajemeoclevnstvi />}
            ></Route>
          </Route>
          {/* --- */}
          {/* TEAM ONE */}
          <Route path="oddil_one" element={<Oddil_OneRootLayout />}>
            <Route path="login" element={<Login_one />}></Route>
            <Route path="home" element={<Home_One />}></Route>
            <Route path="kontakty">
              <Route
                index
                element={<Kontakty_One />}
                loader={OddilOneKontaktyLoader}
              ></Route>
            </Route>
            <Route path="aktuality">
              <Route
                index
                element={<Aktuality_One />}
                loader={OddilOneAktualityLoader}
              ></Route>
              <Route
                path=":id"
                element={<PostPage />}
                loader={postLoader}
              ></Route>
            </Route>
            <Route path="dokumenty">
              <Route
                index
                element={<Dokumenty_One />}
                loader={OddilOneDokumentyLoader}
              ></Route>
              <Route
                path=":id"
                element={<PostPage />}
                loader={postLoader}
              ></Route>
            </Route>
          </Route>
          {/* --- */}
          {/* TEAM 2 */}
          <Route path="oddil_two" element={<Oddil_TwoRootLayout />}>
            <Route path="login" element={<Login_Two />}></Route>
            <Route path="home" element={<Home_Two />}></Route>
            <Route path="kontakty">
              <Route
                index
                element={<Kontakty_Two />}
                loader={OddilTwoKontaktyLoader}
              ></Route>
            </Route>

            <Route path="aktuality">
              <Route
                index
                element={<Aktuality_Two />}
                loader={OddilTwoAktualityLoader}
              ></Route>
              <Route
                path=":id"
                element={<PostPage />}
                loader={postLoader}
              ></Route>
            </Route>
            <Route path="dokumenty">
              <Route
                index
                element={<Dokumenty_Two />}
                loader={OddilTwoDokumentyLoader}
              ></Route>
              <Route
                path=":id"
                element={<PostPage />}
                loader={postLoader}
              ></Route>
            </Route>
          </Route>
          {/* --- */}
          {/* Fotogalarie */}
          <Route path="fotogalerie" element={<FotogalerieRootLayout />}>
            <Route path="login" element={<Login_Foto />}></Route>
            <Route index element={<Fotogalarie />} loader={FotoLoader}></Route>
          </Route>
          {/* --- */}
        </Route>

        <Route path="admin" element={<AdminDefaultRootLayout />}>
          <Route index element={<A_Home />} loader={postsLoader} />
          <Route path="create" element={<A_Create />} />
          <Route path="createcontact" element={<A_CreateContact />} />

          <Route path="createperiod" element={<A_CreatePeriod />}></Route>
          <Route path="createphoto">
            <Route path=":id" element={<A_CreatePhoto />}></Route>
          </Route>
          <Route path="login" element={<A_Login />} />
          <Route path="edit">
            <Route path=":id" element={<A_Edit />} loader={postLoader} />
            <Route path="contact">
              <Route
                path=":id"
                element={<A_EditContact />}
                loader={contactLoader}
              />
            </Route>
            <Route path="photo">
              <Route
                path=":id"
                element={<A_EditPhoto />}
                loader={photoLoader}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    )
  );

  const user = auth.currentUser;

  useEffect(() => {
    auth.signOut();
  }, []);

  return (
    <div className="">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
