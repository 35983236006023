import A_Button from "./A_Button";

const A_PreCreateOverlayContact = ({ postObject, setPostObject }) => {
  return (
    <div>
      <div className="border">
        <h1 className="text-center pt-5">které oddělení? </h1>

        <div className="p-5 d-flex flex-nowrap">
          <A_Button
            text={"1. Oddil"}
            functionOnClick={(e) => {
              setPostObject({ ...postObject, team: 1 });
            }}
            extraStyle={"me-5"}
          ></A_Button>
          <A_Button
            text={"2. Oddil"}
            functionOnClick={(e) => {
              setPostObject({ ...postObject, team: 2 });
            }}
          ></A_Button>
        </div>
      </div>
    </div>
  );
};

export default A_PreCreateOverlayContact;
