import { ref } from "firebase/storage";
import React from "react";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { storage } from "../database/firebase";

export default function PostPage() {
  const [postDetails, setPostDetails] = useState(useLoaderData());

  const getRef = (Doc) => {
    console.log(Doc);
    return ref(storage, Doc.toString());
  };

  return (
    <div>
      <h1>{postDetails.title}</h1>

      <hr />
      {postDetails.pdfs?.map((pdf) => (
        <p>
          stažení <a href={pdf}>{getRef(pdf).name}</a>
        </p>
      ))}
      {postDetails.pdfs?.length > 0 ? <hr /> : <></>}

      <div dangerouslySetInnerHTML={{ __html: postDetails.text }} />
    </div>
  );
}
