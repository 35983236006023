import { useState } from "react";
import { auth } from "../database/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import A_Button from "../components/admin/A_Button";

export default function Login() {
  const [password, setPassword] = useState("");
  const [wrong, setWrong] = useState(false);

  const navigate = useNavigate();

  const signIn = async () => {
    setWrong(false);

    try {
      await signInWithEmailAndPassword(auth, "oddilone@gmail.com", password);

      if (auth.currentUser != null) {
        navigate("../");
      }
    } catch {
      try {
        await signInWithEmailAndPassword(auth, "oddiltwo@gmail.com", password);

        if (auth.currentUser != null) {
          navigate("../");
        }
      } catch {
        setWrong(true);
      }
    }
  };
  return (
    <div className="mx-auto" style={{ maxWidth: "400px", marginTop: "100px" }}>
      <h2 className="">Fotogalerie Přihlášení</h2>
      <div className="mt-3">
        <input
          type="password"
          className="form-control rounded-0"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Heslo"
        />
        {wrong ? (
          <label className="text-bg-danger w-100 mb-2 ps-2">
            Nesprávné heslo
          </label>
        ) : (
          <></>
        )}
        <A_Button
          text={"Přihlásit se"}
          functionOnClick={signIn}
          extraStyle={"mt-3"}
        ></A_Button>
      </div>
    </div>
  );
}
