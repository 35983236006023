import React from "react";
import { storage } from "../../database/firebase";
import { deleteObject, ref } from "firebase/storage";
import A_Button from "./A_Button";

export default function A_DokumentyComponent({ Doc, removeFromList }) {
  const getRef = () => {
    console.log(Doc);
    return ref(storage, Doc.toString());
  };

  const removeDoc = () => {
    deleteObject(ref(storage, Doc.toString()));
    removeFromList(Doc);
  };

  return (
    <>
      <hr />
      <div className="my-1 d-flex justify-content-between">
        <a href={Doc} target="_blank">
          {getRef().name}
        </a>
        <A_Button
          icon="delete"
          full={false}
          theme="danger"
          functionOnClick={removeDoc}
        />
      </div>
    </>
  );
}
