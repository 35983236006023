import React from "react";
import PostComponent from "./PostComponent";
import PhotoComponent from "./PhotoComponent";
import A_Button from "./admin/A_Button";
import PhotoDisplay from "./PhotoDisplay";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../database/firebase";

export default function PeriodDisplay({ periods }) {
  return (
    <div>
      {periods.map((period, index) => (
        <>
          <h3>{period.timePeriod}</h3>
          <PhotoDisplay Array={period.photos} />
          <hr />
        </>
      ))}
    </div>
  );
}
