import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { db, storage } from "../../database/firebase";
import A_TextEditor from "../../components/admin/A_TextEditor";
import A_Button from "../../components/admin/A_Button";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import A_PreCreateOverlayContact from "../../components/admin/A_PreCreateOverlayContact";

const addPhoto = async (photoObject, idOfPeriod) => {
  try {
    const dateOfCreation = serverTimestamp();

    await addDoc(collection(db, "photos"), {
      created: dateOfCreation,
      title: photoObject.title,
      linkTo: photoObject.linkTo,
      summer: photoObject.summer,
      thumbnail: photoObject.thumbnail,
      periodId: idOfPeriod,
    }).then(async (newDoc) => {
      const periodDocRef = doc(db, "periods", idOfPeriod);

      await updateDoc(periodDocRef, {
        photos: arrayUnion(newDoc.id.toString()),
      });
    });
  } catch (err) {
    console.error(err);
  }
};

const A_CreatePhoto = () => {
  const [file, setFile] = useState(null);
  const param = useParams();

  const [photoObject, setPhotoObject] = useState({
    title: "",
    linkTo: "",
    summer: false,
    thumbnail: "",
  });

  const navigate = useNavigate();

  const createNewPhoto = async () => {
    await addPhoto(photoObject, param.id);
    navigate("/admin");
  };

  const uploadFile = async () => {
    const filesFolderRef = ref(storage, `PhotoThumbnails/${file.name}`);

    await uploadBytes(filesFolderRef, file);

    await getDownloadURL(filesFolderRef).then((URL) => {
      setPhotoObject({ ...photoObject, thumbnail: URL });
    });

    alert("Úspěšně nahráno");
  };

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <label className="me-3">Titul</label>
        <input
          className="form-control rounded-0 w-75"
          placeholder="Titul..."
          onChange={(e) =>
            setPhotoObject({ ...photoObject, title: e.target.value })
          }
        />
      </div>

      <hr />
      <div className="d-flex align-items-center justify-content-between">
        <label className="me-3">Adresa URL Disku Google</label>
        <input
          className="form-control rounded-0 w-75"
          placeholder="URL..."
          onChange={(e) =>
            setPhotoObject({ ...photoObject, linkTo: e.target.value })
          }
        />
      </div>
      <hr />
      <div className="d-flex align-items-center justify-content-between">
        <label className="me-3">Náhled</label>
        <div className="w-75 d-flex">
          <input
            className=" form-control rounded-0"
            type="file"
            multiple="multiple"
            accept="image/png, image/jpeg, .heic"
            onChange={onChangeFile}
          />
          <button
            className="btn-primary btn  input-group-text rounded-0"
            onClick={uploadFile}
          >
            Upload
          </button>
        </div>
      </div>
      <hr />
      <div className="d-flex mt-2 align-items-center justify-content-between">
        <label className="me-3">Letní tábor</label>
        <input
          className="form-control form-check-input rounded-0"
          type="checkbox"
          onChange={(e) =>
            setPhotoObject({
              ...photoObject,
              summer: e.target.checked,
            })
          }
        />
      </div>

      {/* Create new post */}
      <hr />
      {photoObject.linkTo != "" ? (
        <A_Button
          functionOnClick={createNewPhoto}
          text={"Zveřejněte odkaz na fotku"}
          icon={"new_window"}
        />
      ) : (
        <A_Button
          text={"Zveřejněte odkaz na fotku"}
          icon={"new_window"}
          disabled={true}
        />
      )}
    </>
  );
};

export default A_CreatePhoto;
